import React, { useEffect, useState } from "react"
import usePageLoading from 'components/utils/usePageLoading'
import { dataStore, filterStore, locationStore, mapStore } from "state/store-zustand";

import Header from "components/header"
import SEO  from "../components/seo"

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'

import BarChart from 'components/elements/charts/bar.jsx'
//import CalendarChart from 'components/elements/charts/calendar-plot.jsx'
import CalendarChart from 'components/elements/charts/calendar-heat.jsx'

import FetchPlaces from 'components/utils/fetchPlaces'
import MapLayout from 'components/map/mapLayout'

import styles from 'react-vis/dist/style.css'
import "styles/pages.scss"

const Dashboard = ({ data, ...props }) => {

  // We're using this page to test page speed.
  const pageLoader = usePageLoading()

  const setZoomCurrent = locationStore(state => state.setZoomCurrent)
  const currentPlace = dataStore(state => state.currentPlace)
  const setCurrentPlace = dataStore(state => state.setCurrentPlace)
  const places = dataStore(state => state.places)
  const numPlaces = dataStore(state => state.numPlaces)
  const numCategories = dataStore(state => state.numCategories)
  const numVibes = dataStore(state => state.numVibes)
  const categoriesTop = filterStore(state => state.categoriesTop)
  const vibesTop = filterStore(state => state.vibesTop)

  // Set the Category stat
  const [numBars, setNumBars] = useState(20)
  const [categoriesChartData, setCategoriesChartData] = useState(null)
  useEffect(() => {
    const data = categoriesTop.map(category => {
      const point = { x: category[1], y: category[0]}
      return point
    })
    setCategoriesChartData(data.slice(0, numBars))
  }, [numCategories, categoriesTop])

  // Set the Vibe stat
  const [vibesChartData, setVibeChartData] = useState(null)
  useEffect(() => {
    const data = vibesTop.map(vibe => {
      const point = { x: vibe[1], y: vibe[0]}
      return point
    })
    setVibeChartData(data.slice(0, numBars))
  }, [numVibes, vibesTop])


  //console.log('DEBUG: stas ', numCategories, categoriesTop, numVibes, vibesTop);

  const cardStyle = { height: '100%' }
  const placeCard = (
    <Card
      sx={{ minWidth: 275 }}
      style={cardStyle}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Places
        </Typography>
        <Typography variant="h3" component="div">
          {numPlaces}
        </Typography>
        <BarChart data={categoriesChartData}/>
      </CardContent>
      <CardActions>
        <Button>Show Data</Button>
      </CardActions>
    </Card>
  )

  const vibeCard = (
    <Card sx={{ minWidth: 275 }} style={cardStyle}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Vibes
        </Typography>
        <Typography variant="h3" component="div">
          {numVibes}
        </Typography>
        <BarChart data={vibesChartData}/>
      </CardContent>
      <CardActions>
        <Button>Show Data</Button>
      </CardActions>
    </Card>
  )

  const eventCard = (
    <Card sx={{ minWidth: 275 }} style={cardStyle}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Events
        </Typography>
        <Typography variant="h3" component="div">
          142
        </Typography>
      </CardContent>
      <CardActions>
        <Button>Show Data</Button>
      </CardActions>
    </Card>
  )

  const chartCard = (
    <Card sx={{ minWidth: 275 }} style={cardStyle}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Vibes
        </Typography>
        <Typography variant="h3" component="div">
          1.4K
        </Typography>
        <BarChart/>
      </CardContent>
      <CardActions>
        <Button>Show Data</Button>
      </CardActions>
    </Card>
  )

  const calendarCard = (
    <Card sx={{ minWidth: 275 }} style={cardStyle}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Event Plot
        </Typography>
        <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
          Events and programming for this month
        </Typography>
        <CalendarChart/>
      </CardContent>
      <CardActions>
        <Button>Show Data</Button>
      </CardActions>
    </Card>
  )

  const mapHeight = 600
  const mapCard = (
    <Card sx={{ minWidth: 275 }} style={cardStyle}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Map Visualization
        </Typography>
        <MapLayout
          title='Dashboard'
          containerHeight={mapHeight}
          useMobileView
          showCircles={true}
          showMarkers={false}
          showMapCards={false}
          zoom={10} />
      </CardContent>
      <CardActions>
        <Button>Show Data</Button>
      </CardActions>
    </Card>
  )

  return (
    <div className={`page ${pageLoader}`}>
        <SEO lang="en-US" title="Dashboard" />
        <main style={{ background: '#e8e8e8'}}>
          <Header />
          <FetchPlaces />
          <div className='container'>
            <section>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      {placeCard}
                    </Grid>
                    <Grid item xs={6}>
                      {vibeCard}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      {eventCard}
                    </Grid>
                    <Grid item xs={6}>
                      {calendarCard}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  {/* Map goes here */}
                  {mapCard}
                </Grid>
              </Grid>
            </section>
          </div>
        </main>
    </div>
  )
}

export default Dashboard